import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getCompany: ['company_slug', 'place_slug'],
  getCompanySuccess: [
    'company',
    'place',
    'company_slug',
    'place_slug',
    'theme',
  ],
  getCompanyFail: ['error'],
  logout: [],
});

export const CompanyTypes = Types;
export const CompanyActions = Creators;

/* Selectors */

const selectCompany = createSelector(
  state => state.company,
  company => company.company,
);

const selectPlace = createSelector(
  state => state.company,
  company => company.place,
);

const selectTheme = createSelector(
  state => state.company,
  company => company.theme,
);

const selectCompanySlug = createSelector(
  state => state.company,
  company => company.company_slug,
);

const selectPlaceSlug = createSelector(
  state => state.company,
  company => company.place_slug,
);

export const CompanySelectors = {
  selectCompany,
  selectPlace,
  selectTheme,
  selectCompanySlug,
  selectPlaceSlug,
};

/* Initial State */

export const INITIAL_STATE = Immutable({
  loading: false,
  company: null,
  place: null,
  company_slug: null,
  place_slug: null,
  prize: null,

  theme: {},
});

/* Reducers */

export const get = (state, { company_slug, place_slug }) =>
  state.merge({ loading: true, company_slug, place_slug });

export const success = (
  state,
  { company, place, company_slug, place_slug, theme },
) =>
  state.merge({
    loading: false,
    company,
    place,
    company_slug,
    place_slug,
    theme,
  });

export const fail = () => INITIAL_STATE;

export const logout = () => INITIAL_STATE;

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.GET_COMPANY]: get,
  [Types.GET_COMPANY_SUCCESS]: success,
  [Types.GET_COMPANY_FAIL]: fail,
  [Types.LOGOUT]: logout,
});
