import React, { useState } from 'react';
import * as S from './styled';

import StarFilled from '../../images/icons/star_filled.svg';
import StarEmpty from '../../images/icons/star_empty.svg';

const ScoreButton = ({ onSubmit }) => {
  const [current, setCurrent] = useState(0);

  return (
    <S.ScoreButtonWrapper>
      {[1, 2, 3, 4, 5].map(star => (
        <S.Star
          src={star <= current ? StarFilled : StarEmpty}
          alt="Score"
          onClick={() => onSubmit(star)}
          onMouseEnter={() => setCurrent(star)}
        />
      ))}
    </S.ScoreButtonWrapper>
  );
};

export default ScoreButton;
