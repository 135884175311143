import React from 'react';
import { withTheme } from '../../themes';
import * as S from './styled';

const Header = ({ company, theme }) => {
  return (
    <S.Wrapper>
      <S.Icon
        src={theme.icon.src}
        height={theme.icon.height}
        width={theme.icon.width}
      />
      <S.TitleWrapper>
        <S.Title>{company}</S.Title>
        <S.Subtitle>Disponível</S.Subtitle>
      </S.TitleWrapper>
    </S.Wrapper>
  );
};

export default withTheme(Header);
