import styled from 'styled-components';
import { withTheme } from '../../themes';

export const ScoreButtonWrapper = withTheme(styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 50px 0;
  width: 306px;
  height: 62px;
  border-radius: 18px;
  border-style: solid;
  border-width: 2px;
  border-color: ${props => props.theme.primaryColor};
`);

export const Star = styled.img`
  width: 42px;
  height: 42px;
  margin: 0 8px;

  &:hover {
    transform: scale(1.1);
  }
`;
