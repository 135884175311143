import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactLoading from 'react-loading';
import _ from 'lodash';
import Chat from './containers/Chat';
import { ThemeProvider } from './themes';
import GlobalStyle from './global-styles';
import { CompanySelectors, CompanyActions } from './redux/ducks/CompanyRedux';
// import PerolaThemes from './themes/perola';

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const company_param = urlParams.get('company');
  const place_param = urlParams.get('place');

  const dispatch = useDispatch();
  const company = useSelector(CompanySelectors.selectCompany);
  const place = useSelector(CompanySelectors.selectPlace);
  const theme = useSelector(CompanySelectors.selectTheme);

  useEffect(() => {
    const subdomain = (window.location.host.split('.')[1]
      ? window.location.host.split('.')[0]
      : ''
    ).replace('https://', '');

    dispatch(
      CompanyActions.getCompany(
        company_param || subdomain,
        place_param || 'salao',
      ),
    );
  }, [dispatch, company_param, place_param]);

  useEffect(() => {
    console.log('SLUGS', company, place);
  }, [company, place]);

  if (_.isEmpty(company)) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ReactLoading type="bubbles" color="#00B6E3" width={150} />
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Chat />
      <GlobalStyle />
    </ThemeProvider>
  );
}

export default App;
