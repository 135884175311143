import { call, put } from 'redux-saga/effects';
import { path } from 'ramda';
import { formatWithOptions } from 'date-fns/fp';
import { ptBR } from 'date-fns/locale';
import uuidv4 from 'uuid/v4';
import { AuthActions } from '../ducks/AuthRedux';
import { ChatActions } from '../ducks/ChatRedux';

const dateToString = formatWithOptions({ locale: ptBR }, 'HH:mm');

function sleep() {
  const sec = Math.random() * (3 - 1) + 1;
  return new Promise(resolve => setTimeout(resolve, sec * 1000));
}

export function* create_conversation(
  api,
  { user_identifier, company_slug, place_slug },
) {
  if (!user_identifier) {
    user_identifier = uuidv4();
  }
  const response = yield call(
    api.conversations,
    user_identifier,
    company_slug,
    place_slug,
  );
  const id = path(['data', 'id'], response);

  if (id) {
    yield put(ChatActions.createConversationSuccess(id));
    yield put(AuthActions.setIdentifier(user_identifier));
  }
}

export function* send_message(
  api,
  { conversation, answer, in_response_to, answer_type },
) {
  if (in_response_to && answer.length > 0) {
    yield put(
      ChatActions.addMessage({
        sender: 1,
        text: answer,
        answer_type,
        date: dateToString(new Date()),
      }),
    );
  }

  yield put(ChatActions.setLoading(true));
  const response = yield call(api.chat, conversation, answer, in_response_to);

  if (!response.problem) {
    const messages = path(['data', 'messages'], response);
    const intent = path(['data', 'identifier'], response);
    const options = path(['data', 'options'], response);
    const intent_answer_type = path(['data', 'answer_type'], response);
    const end_conversation = path(['data', 'end_conversation'], response);

    // To clean Inputs options and score stars
    yield put(ChatActions.setIntent(intent, null, options));

    let index = 0;
    while (true) {
      if (index < messages.length) {
        const message = messages[index];
        yield put(ChatActions.setLoading(true));
        yield sleep();
        yield put(
          ChatActions.addMessage({
            text: message,
            date: dateToString(new Date()),
          }),
        );
        yield put(ChatActions.setLoading(false));
        index++;
      } else {
        break;
      }
    }

    yield put(ChatActions.setLoading(true));
    yield sleep();
    yield put(
      ChatActions.setIntent(
        intent,
        end_conversation === true ? 'END' : intent_answer_type,
        options,
      ),
    );

    yield put(ChatActions.setLoading(false));

    if (intent_answer_type === 'NONE' && !end_conversation) {
      yield put(
        ChatActions.sendMessage(conversation, '', intent, intent_answer_type),
      );
    }
  }
}
