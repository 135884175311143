import styled from 'styled-components';
import { withTheme } from '../../themes';

export const Wrapper = withTheme(styled.div`
  background: ${props => props.theme.sender.background};
  box-shadow: ${props => props.theme.sender.shadow};
  height: ${props => props.theme.sender.height}px;

  display: flex;
  align-items: center;
  padding: 0 16px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
`);

export const Input = withTheme(styled.input`
  flex: 1;
  outline: 0;
  height: 36px;
  border-radius: 18px;
  padding: 0 15px;
  margin: 0 16px;
  border: ${props => props.theme.input.border};
  background: ${props => props.theme.input.background};
  color: ${props => props.theme.input.text_color};
  letter-spacing: 0;
  text-align: left;
  font-family: SFCompactText-Regular;
  font-size: 16px;

  ::placeholder {
    color: ${props => props.theme.input.placeholder_color};
  }
`);

export const SendButton = withTheme(styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  height: 47px;
  width: 47px;
  border-radius: 23.5px;
  background: ${props => props.theme.button.background};
  box-shadow: ${props => props.theme.button.shadow};
  border: 0;

  :hover {
    opacity: 0.7;
  }
`);

export const Emoticon = withTheme(styled.img`
  height: 27px;
  width: 27px;
`);

export const Check = withTheme(styled.img`
  height: 13px;
  width: 17px;
`);
