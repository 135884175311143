import React from 'react';
import { parseISO, format } from 'date-fns';
import { pt } from 'date-fns/locale';
import { useTheme } from '../../themes';
import * as S from './styled';

import StarFilled from '../../images/icons/star_filled.svg';
import StarEmpty from '../../images/icons/star_empty.svg';

const Message = ({ loading, message = { text: '' }, to }) => {
  const theme = useTheme();

  const renderScore = score => {
    return [1, 2, 3, 4, 5].map(star => (
      <S.Star src={star <= score ? StarFilled : StarEmpty} alt="Score" />
    ));
  };

  const formatText = (text, answer_type) => {
    if (answer_type === 'DATE') {
      return format(parseISO(text), "'Dia' dd 'de' MMMM 'de' yyyy", {
        locale: pt,
      });
    }

    return text;
  };

  const formatLink = (text = '') => {
    const [normal, link1, link2] = text.split(':');
    return (
      <span>
        {normal}:{' '}
        <a href={`${link1}:${link2}`} target="_blank" rel="noopener noreferrer">
          {`${link1}:${link2}`}
        </a>
      </span>
    );
  };

  const haslink = message.text.includes('http');
  console.log(haslink, 'log aqui');

  return (
    <S.MessageContainer to={to}>
      {haslink ? (
        <S.MessageWrapper to={to}>
          {loading && (
            <S.LoadingWrapper>
              <S.Loading src={theme.loading} alt="Loading" />
            </S.LoadingWrapper>
          )}
          {message != null && formatLink(message.text, message.answer_type)}
          {message != null && (
            <>
              <br />
              <S.HourWrapper to={to}>{message.date}</S.HourWrapper>
            </>
          )}
        </S.MessageWrapper>
      ) : (
        <S.MessageWrapper to={to}>
          {loading && (
            <S.LoadingWrapper>
              <S.Loading src={theme.loading} alt="Loading" />
            </S.LoadingWrapper>
          )}
          {message != null &&
            message.answer_type !== 'SCORE' &&
            formatText(message.text, message.answer_type)}
          {message != null &&
            message.answer_type === 'SCORE' &&
            renderScore(parseInt(message.text))}
          {message != null && (
            <>
              <br />
              <S.HourWrapper to={to}>{message.date}</S.HourWrapper>
            </>
          )}
        </S.MessageWrapper>
      )}
    </S.MessageContainer>
  );
};

export default Message;
