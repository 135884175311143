import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SenderInput from '../../components/SenderInput';
import ScoreButton from '../../components/ScoreButton';
import Options from '../../components/Options';
import NPS from '../../components/NPS';
import Header from '../../components/Header';
import Message from '../../components/Message';
import * as S from './styled';
import { AuthSelectors } from '../../redux/ducks/AuthRedux';
import { CompanySelectors } from '../../redux/ducks/CompanyRedux';
import { ChatSelectors, ChatActions } from '../../redux/ducks/ChatRedux';

const Chat = () => {
  const dispatch = useDispatch();

  const user_identifier = useSelector(AuthSelectors.selectIdentifier);
  const conversation = useSelector(ChatSelectors.selectConversation);

  const messages = useSelector(ChatSelectors.selectMessages);
  const options = useSelector(ChatSelectors.selectOptions);
  const loading = useSelector(ChatSelectors.selectLoading);
  const intent = useSelector(ChatSelectors.selectIntent);
  const answer_type = useSelector(ChatSelectors.selectAnswerType);
  const company = useSelector(CompanySelectors.selectCompany);
  const company_slug = useSelector(CompanySelectors.selectCompanySlug);
  const place_slug = useSelector(CompanySelectors.selectPlaceSlug);
  const scrollRef = useRef();

  useEffect(() => {
    dispatch(ChatActions.clear());

    const vh = window.innerHeight * 0.01;

    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }, [dispatch]);

  useEffect(() => {
    if (conversation) {
      dispatch(ChatActions.sendMessage(conversation, 'Welcome'));
    } else {
      dispatch(
        ChatActions.createConversation(
          user_identifier,
          company_slug,
          place_slug,
        ),
      );
    }
  }, [
    company,
    company_slug,
    conversation,
    dispatch,
    place_slug,
    user_identifier,
  ]);

  useEffect(() => {
    scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
  }, [messages, answer_type, loading]);

  const onSubmit = text => {
    dispatch(
      ChatActions.sendMessage(conversation, String(text), intent, answer_type),
    );
  };

  return (
    <S.ChatWrapper>
      <Header company={company} />
      <S.MessagesWrapper ref={scrollRef}>
        <S.MessagesContainer>
          {messages.map((message, index) => (
            <Message key={index} to={message.sender === 1} message={message} />
          ))}
          {loading && <Message loading={loading} />}
          {/* {(current_state === WAITING_AMBIANCE || current_state === WAITING_AMBIANCE_CONFIRMATION || current_state === WAITING_FOOD || current_state === WAITING_FOOD_CONFIRMATION) &&
             <div style={{height: 130}}></div>
          } */}

          {answer_type === 'SCORE' && <ScoreButton onSubmit={onSubmit} />}
          {answer_type === 'OPTIONS' && (
            <Options options={options} onSubmit={onSubmit} />
          )}
          {answer_type === 'INTEGER' && <NPS onSubmit={onSubmit} />}
        </S.MessagesContainer>
      </S.MessagesWrapper>
      <SenderInput
        type={
          answer_type === 'INTEGER' || answer_type === 'PHONE'
            ? 'number'
            : answer_type === 'EMAIL'
            ? 'email'
            : answer_type === 'DATE'
            ? 'date'
            : 'text'
        }
        disabled={
          loading ||
          answer_type === 'END' ||
          answer_type === 'INTEGER' ||
          answer_type === 'SCORE' ||
          answer_type === 'OPTIONS'
        }
        onSubmit={onSubmit}
      />
    </S.ChatWrapper>
  );
};

export default Chat;
