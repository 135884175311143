import styled from 'styled-components';
import { withTheme } from '../../themes';

export const OptionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 50px 0;
`;

export const Option = withTheme(styled.p`
  height: 34px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  font-family: SFCompactText-Regular;
  font-size: 16px;
  margin: 0 10px;
  box-shadow: ${props =>
    props.index % 2 !== 0
      ? props.theme.message.to.shadow
      : props.theme.message.from.shadow};
  background: ${props =>
    props.index % 2 !== 0
      ? props.theme.message.to.background
      : props.theme.message.from.background};
  color: ${props =>
    props.index % 2 !== 0
      ? props.theme.message.to.text_color
      : props.theme.primaryColor};

  &:hover {
    opacity: 0.7;
  }
`);
