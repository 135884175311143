import { takeLatest, all } from 'redux-saga/effects';
import API from '../../services/Api';

/* ------------- Types ------------- */

// import { AuthTypes } from '../ducks/AuthRedux';
import { CompanyTypes } from '../ducks/CompanyRedux';
import { ChatTypes } from '../ducks/ChatRedux';

/* ------------- Sagas ------------- */

// import { logout } from './AuthSagas';
import { company } from './CompanySagas';
import { create_conversation, send_message } from './ChatSagas';

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
const api = API.create();

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([
    // takeLatest(AuthTypes.LOGOUT, logout, api),
    takeLatest(CompanyTypes.GET_COMPANY, company, api),
    takeLatest(ChatTypes.CREATE_CONVERSATION, create_conversation, api),
    takeLatest(ChatTypes.SEND_MESSAGE, send_message, api),
  ]);
}
