import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  setUser: ['name'],
  setIdentifier: ['identifier'],
  logout: [],
});

export const AuthTypes = Types;
export const AuthActions = Creators;

/* Selectors */

const selectName = createSelector(
  state => state.auth,
  auth => auth.name,
);

const selectIdentifier = createSelector(
  state => state.auth,
  auth => auth.identifier,
);

export const AuthSelectors = {
  selectName,
  selectIdentifier,
};

/* Initial State */

export const INITIAL_STATE = Immutable({
  loading: false,
  name: null,
  identifier: null,
});

/* Reducers */

export const setUser = (state, { name }) => state.merge({ name });

export const setItendifier = (state, { identifier }) =>
  state.merge({ identifier });

export const logout = () => INITIAL_STATE;

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.SET_USER]: setUser,
  [Types.SET_IDENTIFIER]: setItendifier,
  [Types.LOGOUT]: logout,
});
