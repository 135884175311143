import { combineReducers } from 'redux';
import Auth from './AuthRedux';
import Company from './CompanyRedux';
import Chat from './ChatRedux';

const reducers = combineReducers({
  auth: Auth,
  company: Company,
  chat: Chat,
});

export default reducers;
