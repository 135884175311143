import apisauce from 'apisauce';
import { store } from '../redux/store';

const create = (
  // baseURL =  "http://localhost:8000"
  baseURL = 'https://api.falacliente.com',
) => {
  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {
      'Cache-Control': 'no-cache',
    },
    // 60 second timeout...
    timeout: 60000,
  });

  const company = (company_slug, place_slug) =>
    api.get(`company/${company_slug}/${place_slug}`);
  const conversations = (user_identifier, company_slug, place_slug) =>
    api.post('chat/', { user_identifier, company_slug, place_slug });
  const chat = (conversation, answer, in_response_to) =>
    api.put('chat/', { conversation, answer, in_response_to });

  api.addRequestTransform(request => {
    const state = store.getState();
    if (state.auth.token) {
      request.headers.Authorization = `Token ${state.auth.token}`;
    }
  });

  return {
    company,
    conversations,
    chat,
  };
};

export default {
  create,
};
